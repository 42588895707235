<template>
  <div class="content">
    
   <h1>お申込完了</h1>
    <p>ありがとうございました。お申し込みが完了しました！</p>

  <router-link @click.native="scrollToTop" to="/">トップへ戻る</router-link>


  </div>
</template>

<script>
export default  {
    created: function() { this.$parent.middle = 2; }}
</script>

<style scoped>
.content{
  color: #000;
}
</style>